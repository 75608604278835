
// Primary-colors
$primary-color:  #2368ff; //color for: Page = 1
$primary-color-2:  #6d3cd6; //color for: Hero = 2




// Landing1 Fonts and colors
$primary-font: 'Inter';
$l1-primary-font: 'Inter';
$l1-primary-heading: #19191b;
$l1-primary-text: #696871;
$l1-primary-color:  #2368ff;
$l1-secondary-color: #5454d4;


$green-btn: #00e186;
$blue-btn: #4a92f5;





// Screen Width
$screen-xxs: 320px;
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1366px;
$screen-xxxl: 1600px;

.bg-whisper{
  background: #F7F7FB;
}